import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import client from "../../../../services/core/limboclient";
import moment from "moment";
import MoodsTable from "../../../client/components/moods-table";
import { CheckBox } from "@mui/icons-material";
import { toast } from "react-toastify";

const DATE_FORMAT = "DD.MM.YYYY-HH:MM";

const TechnicsTab = ({ clientId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [techniques, setTechniques] = useState([]);
  const [presTechniques, setPresTechniques] = useState([]);

  const [moodsList, setMoodsList] = useState([]);
  const [moodsFrom, setMoodsFrom] = useState(moment().subtract(1, "day"));
  const [moodsTo, setMoodsTo] = useState(moment());
  const [moodsCategories, setMoodsCategories] = useState([]);

  const [thoughtRecords, setThoughtRecords] = useState([]);
  const [thoughtFrom, setThoughtFrom] = useState(moment().subtract(1, "day"));
  const [thoughtTo, setThoughtTo] = useState(moment());

  useEffect(() => {
    client.call(`=>technique_getTechniques ~`).then((result) => {
      setTechniques(result);
    });
  }, []);

  useEffect(() => {
    getPrescribedTechniques();
  }, []);

  useEffect(() => {
    client
      .call(`=>mood_getCategories ~`)
      .then((result) => setMoodsCategories(result));
  }, []);

  useEffect(() => {
    if (moodsFrom || moodsTo) {
      client
        .call(
          `=>mood_getRecords ~ ${JSON.stringify({
            client: clientId,
            ...(moodsFrom && { from: moment(moodsFrom).format("DD.MM.YYYY") }),
            ...(moodsTo && { to: moment(moodsTo).format("DD.MM.YYYY") }),
          })}`
        )
        .then((result) => setMoodsList(result));
    }
  }, [moodsFrom, moodsTo, clientId]);

  useEffect(() => {
    if (techniques.length > 0) {
      client
        .call(
          `
            =>thoughtTracker_getMyClientThougthRecords ~ {
              "client" : "${clientId}",
              "dateFrom": "${moment(thoughtFrom).format(DATE_FORMAT)}",
              "dateTo": "${moment(thoughtTo).format(DATE_FORMAT)}"
            }
          `
        )
        .then((result) => {
          setThoughtRecords(result);
        });
    }
  }, [thoughtFrom, thoughtTo, activeTab, techniques, clientId]);

  const getPrescribedTechniques = () => {
    client
      .call(
        `=>technique_getPrescribedTechniques ~ {
        "client" : "${clientId}"
      }`
      )
      .then((result) => {
        setPresTechniques(result);
      });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const assignTechnic = (techniqueID) => {
    console.log(`technique_prescribe ~ {
          "client" : "${clientId}",
          "technique" : "${techniqueID}"
        }`);
    client
      .call(
        `technique_prescribe ~ {
          "client" : "${clientId}",
          "technique" : "${techniqueID}"
        }`
      )
      .then(() => getPrescribedTechniques());
  };

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
      >
        {[{ id: "mood", name: "Настрій" }, ...techniques].map((tech) => (
          <Tab
            key={tech.id}
            label={tech.name}
          />
        ))}
      </Tabs>

      <Box pt={3}>
        {activeTab === 0 && (
          <Box className='border border-[#a1a1a1] rounded-lg p-5 mt-5'>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <p className='font-bold text-xl'>Дошка зміни настрою</p>
              <Box
                display='flex'
                alignItems='center'
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      label='Фільтрувати від'
                      value={moodsFrom}
                      onChange={(newValue) => setMoodsFrom(newValue)}
                      className='w-[300px] !ml-5'
                      format='DD.MM.YYYY'
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      label='Фільтрувати до'
                      value={moodsTo}
                      onChange={(newValue) => setMoodsTo(newValue)}
                      className='w-[300px] !ml-5'
                      format='DD.MM.YYYY'
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
            <Box mt={3}>
              {moodsList?.length > 0 ? (
                <MoodsTable
                  moodsList={moodsList}
                  moodsCategories={moodsCategories}
                />
              ) : (
                <em className='opacity-70 text-xl mt-7 flex justify-center'>
                  Відмітки настрою відсутні...
                </em>
              )}
            </Box>
          </Box>
        )}

        {activeTab === 1 && (
          <Box className='border border-[#a1a1a1] rounded-lg p-5 mt-5'>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <p className='font-bold text-xl'>Щоденник думок</p>
              <Box
                display='flex'
                alignItems='center'
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      label='Фільтрувати від'
                      value={thoughtFrom}
                      onChange={(newValue) => setThoughtFrom(newValue)}
                      className='w-[300px] !ml-5'
                      format='DD.MM.YYYY'
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      label='Фільтрувати до'
                      value={thoughtTo}
                      onChange={(newValue) => setThoughtTo(newValue)}
                      className='w-[300px] !ml-5'
                      format='DD.MM.YYYY'
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={presTechniques.some(
                    (tech) => tech.flows === "thoughtTracker"
                  )}
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      console.log(
                        techniques.find((tec) => tec.flows === "thoughtTracker")
                      );
                      console.log(
                        presTechniques.some(
                          (tech) => tech.flows === "thoughtTracker"
                        )
                      );
                      assignTechnic(
                        techniques.find((tec) => tec.flows === "thoughtTracker")
                          ?.id
                      );
                      toast("Техніку призначено");
                    } else {
                      toast("Техніку вже призначено");
                    }
                  }}
                  className='!p-0.5'
                />
              }
              label={"Призначено"}
            />

            <Box mt={3}>
              {thoughtRecords?.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell>Ситуація</TableCell>
                        <TableCell>Емоція</TableCell>
                        <TableCell>Сила емоції</TableCell>
                        <TableCell>Думка</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {thoughtRecords.map((record) => (
                        <TableRow key={record.id}>
                          <TableCell>
                            {moment(record.tracked).format("DD.MM.YYYY HH:mm")}
                          </TableCell>
                          <TableCell>{record.situation}</TableCell>
                          <TableCell>{record.emotion}</TableCell>
                          <TableCell>{record.emotionStrength}</TableCell>
                          <TableCell>{record.thought}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <em className='opacity-70 text-xl mt-7 flex justify-center'>
                  Записів немає...
                </em>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TechnicsTab;
