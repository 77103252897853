import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import SignIn from "./pages/auth/sign-in";
import SideBar from "./components/main/side-bar";
import SignUp from "./pages/auth/sign-up";
import client from "./services/core/limboclient";
import Confirm from "./pages/auth/confirm";
import TherapyTestsPage from "./pages/therapy-tests";
import TherapyClientsPage from "./pages/therapy-clients";
import TherapySchedulePage from "./pages/therapy-schedule";
import TherapyAppointmentPage from "./pages/therapy-appointment";
import TherapyAppointmentUnassignedPage from "./pages/therapy-appointment-unassigned";
import AdminUsersPage from "./pages/admin-users";
import AdminTherapistsPage from "./pages/admin-therapists";
import AdminClientsPage from "./pages/admin-new-clients";
import LogsPage from "./pages/logs";
import ClientPage from "./pages/client";
import SystemProfilePage from "./pages/system-profile";
import clsx from "clsx";
import ProfilePage from "./pages/profile";
import "moment/locale/uk";
import MoodtrackerPage from "./pages/moodtracker";
import AdminTransactionPage from "./pages/admin-transaction";
import EditTherapistPage from "./pages/admin-therapist-profile";
import { ToastContainer } from "react-toastify";

const App = () => {
  const navigate = useNavigate();
  const isRemoveProfilePage = window.location.pathname === "/r/user/profile";
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    client.call(`=>user_isAuthorized~`).then((isAuthorizedResult) => {
      if (isAuthorizedResult !== isAuthorized)
        setIsAuthorized(isAuthorizedResult);
      if (
        !isAuthorizedResult &&
        !~window.location.href.indexOf("sign-up") &&
        !~window.location.href.indexOf("confirm") &&
        !isRemoveProfilePage
      )
        navigate("/r/sign-in");
    });
  }, [isAuthorized, isRemoveProfilePage, navigate]);

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }

    function handleOffline() {
      setIsOnline(false);
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div className='App'>
      {!isOnline && (
        <div
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            backgroundColor: "#f44336",
            color: "#fff",
            textAlign: "center",
            padding: "10px",
            zIndex: 9999,
          }}
        >
          Ви зараз не в мережі. Деякі функції можуть бути недоступні.
        </div>
      )}

      <div
        className='container'
        style={{ marginTop: !isOnline ? "40px" : "0" }}
      >
        {!isRemoveProfilePage && (
          <div className='sidebar'>
            <SideBar />
          </div>
        )}
        <div
          className={clsx("contentContainer", isRemoveProfilePage && "!p-0")}
        >
          <Routes>
            <Route
              path='/r/calendar/setSchedule'
              element={<TherapySchedulePage />}
            />
            <Route
              path='/r/appointment/list'
              element={<TherapyAppointmentPage />}
            />
            <Route
              path='/r/client/list'
              element={<TherapyClientsPage />}
            />
            <Route
              path='/r/client/view'
              element={<ClientPage />}
            />
            <Route
              path='/r/profile'
              element={<ProfilePage />}
            />
            <Route
              path='/r/test/index'
              element={<TherapyTestsPage />}
            />
            <Route
              path='/r/user/profile'
              element={<SystemProfilePage />}
            />
            <Route
              path='/r/appointment/unassigned'
              element={<TherapyAppointmentUnassignedPage />}
            />
            <Route
              path='/r/admin/users'
              element={<AdminUsersPage />}
            />
            <Route
              path='/r/admin/logs'
              element={<LogsPage />}
            />
            <Route
              path='/r/admin/transaction'
              element={<AdminTransactionPage />}
            />
            <Route
              path='/r/moodtracker/moodmanager'
              element={<MoodtrackerPage />}
            />
            <Route
              path='/r/admin/therapists'
              element={<AdminTherapistsPage />}
            />
            <Route
              path='/r/admin/therapist'
              element={<EditTherapistPage />}
            />
            <Route
              path='/r/admin/clients'
              element={<AdminClientsPage />}
            />
            <Route
              path='/r/sign-in/:id'
              element={<SignIn />}
            />
            <Route
              path='/r/sign-in'
              element={<SignIn />}
            />
            <Route
              path='/r/sign-up'
              element={<SignUp />}
            />
            <Route
              path='/r/confirm'
              element={<Confirm />}
            />
            <Route
              path={"*"}
              element={<SignIn />}
            />
          </Routes>
        </div>
      </div>

      <ToastContainer
        position={"bottom-left"}
        autoClose={5000}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </div>
  );
};

export default App;
